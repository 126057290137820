import { BaseClass } from './base'

export class CouponsClass extends BaseClass {
  modelRef = {
    title: '',
    comsumeType: 0,
    isOn: true,
    money: 0
  }

  rulesRef = {
    title: [
      {
        required: true,
        message: '请输入名称'
      }
    ]
  }

  gets (params) {
    return this.get(this.coupons, params)
  }

  detail (id) {
    return this.get(this.coupons + `/${id}`)
  }

  create (data) {
    return this.post(this.coupons, data)
  }

  update (data) {
    return this.put(this.coupons, data)
  }

  del (id) {
    return this.delete(this.coupons + `/${id}`)
  }

  state (id, state) {
    return this.get(this.coupons + `/${id}/${state}`)
  }
}
