<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="400"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          :labelCol="6"
          title="标题"
          :validate="validateInfos.title"
          v-model:value="modelRef.title"
        />

        <e-input-number
          :labelCol="6"
          title="金额"
          v-model:value="modelRef.money"
          :min="0"
        />

        <e-radio
          :labelCol="6"
          title="类型"
          v-model:value="modelRef.comsumeType"
          :options="options"
        />

        <e-switch
          :labelCol="6"
          title="是否生效"
          v-model:value="modelRef.isOn"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { CouponsClass } from '@/apis/coupons'

const useForm = Form.useForm
const api = new CouponsClass()
export default defineComponent({
  components: {},
  mixins: [form],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      modelRef,
      validateInfos,
      options: [
        {
          label: '线上',
          value: 1
        },
        {
          label: '线下',
          value: 0
        }
      ],
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.title = formData.title
        this.modelRef.comsumeType = formData.comsumeType
        this.modelRef.isOn = formData.isOn
        this.modelRef.money = formData.money
        this.modelRef.id = formData.id

        this.title = '编辑：' + formData.title
        this.id = formData.id
      } else {
        this.modelRef = reactive(api.modelRef)

        this.title = '添加优惠券'
        this.id = 0
      }

      this.visible = true
    },

    onSubmitFun (e) {
      const data = toRaw(e)

      if (this.id) {
        api.update(data).then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$emit('ok', data)
          this.onClose()
        })
      } else {
        api.create(data).then(() => {
          this.loading = false
          this.$message.success('添加成功')
          this.$emit('ok', data)
          this.onClose()
        })
      }
    }
  }
})
</script>

<style lang="less" scoped></style>
